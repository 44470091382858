import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  DropdownComponent as component,
  DropdownMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const Dropdown = connect(map)(component)

/**
 * Initializes all Dropdown instances called for by the page.
 * @param store - The Redux store.
 */

const initDropdown = store => {
  const selects = initSelect('select:not(.initialized):not(.tag-select):not([multiple])')
  selects.forEach((select, index) => {
    const name = select.getAttribute('name') ? select.getAttribute('name') : `${index}`
    const options = utils.getOptions(select)
    const wrapper = utils.replaceWrap(select)
    utils.addClass(wrapper, 'dropdown-wrapper')

    render(
      <Container store={store}>
        <Dropdown
          id={`d${index}`}
          name={name}
          options={options} />
      </Container>,
      wrapper
    )
  })
}

export {
  Dropdown,
  initDropdown
}
