import '../../js/src/index'
import './prism'

import { Container } from '../../js/src/enhancements/container'
import { Dropdown } from '../../js/src/enhancements/dropdown'
import React from 'react'
import { render } from 'react-dom'

import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from '../../js/src/reducers'

const jump = (option) => {
  window.location.hash = `#${option.value ? option.value : option}`
}

const addSingleNav = (arr, wrapper, store) => {
  const raw = arr.map(item => ({ value: item.id, text: item.label }))
  const options = [ { value: '', text: 'Choose a section...' }, ...raw ]

  render(
    <React.Fragment>
      <p style={{ margin: '2em 0 0 0' }}>
        <strong>Jump to:</strong>
      </p>
      <Container store={store}>
        <Dropdown
          name='design-jump-nav'
          options={options}
          onSelect={jump}
          filterable={false} />
      </Container>
      <hr />
    </React.Fragment>,
    wrapper
  )
}

const addCategorizedNav = (nav, wrapper, store) => {
  const keys = Object.keys(nav)
  const categories = keys.map(key => ({ text: key, value: key }))
  const items = [ { text: 'Choose a pattern...', value: '#' } ]

  keys.forEach(key => {
    nav[key].forEach(item => {
      items.push({
        check: `design-jump-category=${key}`,
        text: item.label,
        value: item.id
      })
    })
  })

  render(
    <React.Fragment>
      <p style={{ margin: '2em 0 0 0' }}>
        <strong>Jump to:</strong>
      </p>
      <Container store={store}>
        <div className='row'>
          <div className='col5'>
            <Dropdown
              name='design-jump-category'
              options={categories}
              filterable={false} />
          </div>
          <div className='col7'>
            <Dropdown
              name='design-jump-nav'
              options={items}
              onSelect={jump}
              filterable={false} />
          </div>
        </div>
      </Container>
    </React.Fragment>,
    wrapper
  )
}

const getNext = (ref, selector) => {
  const next = ref.nextElementSibling
  if (next && next.matches(selector)) {
    return next
  } else if (next) {
    return getNext(next, selector)
  } else {
    return null
  }
}

const addNav = () => {
  const anchors = Array.from(document.querySelectorAll('a.nav-anchor'))
  const nav = {}
  anchors.forEach(anchor => {
    const id = anchor.getAttribute('name')
    const section = getNext(anchor, 'section')
    const heading = section ? section.querySelector('h1, h2, h3') : null
    const label = anchor.label ? anchor.label : heading ? heading.textContent : 'Untitled'
    const category = anchor.dataset.category ? anchor.dataset.category : 'Uncategorized'
    if (Array.isArray(nav[category])) {
      nav[category].push({ label, id })
    } else {
      nav[category] = [{ label, id }]
    }
  })

  const wrapper = document.getElementById('design-jump-nav')
  if (wrapper) {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    const store = devTools
      ? createStore(reducers, compose(applyMiddleware(thunk), devTools))
      : createStore(reducers, applyMiddleware(thunk))

    const keys = Object.keys(nav)
    if ((keys.length === 1) && (keys[0] === 'Uncategorized')) {
      addSingleNav(nav['Uncategorized'], wrapper, store)
    } else {
      addCategorizedNav(nav, wrapper, store)
    }
  }
}

addNav()
