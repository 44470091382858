/* global FormData, XMLHttpRequest */

import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  FileInputDragComponent as component,
  FileInputDragMap as map,
  FileInputDragActions as actions,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const FileInputDrag = connect(map)(component)

/**
 * Uploads files.
 * @param meta {Object} - An object containing key/value pairs of miscellaneous
 *   information stored in the FileInputDrag Redux store.
 * @param files {Array} - An array of files to be uploaded.
 * @param dispatch {func} - The Redux store's dispatch method used by the
 *   FileInputDrag component.
 */

const onUpload = (meta, files, dispatch) => {
  const { demo, param, upload } = meta
  if (param && upload && !demo) {
    const data = new FormData()
    for (let file of files) data.append(`${param}[]`, file)
    const xhr = new XMLHttpRequest()
    xhr.open('POST', upload, true)
    xhr.onProgress = event => {
      dispatch(actions.setProgress(meta.name, (event.loaded / event.total) * 100))
    }
    xhr.onLoad = event => {
      const errMsg = 'Something went wrong with your upload.'
      dispatch(actions.clearProgress(meta.name))
      if (xhr.status === 200) {
        dispatch(actions.removeError(meta.name, errMsg))
      } else if (xhr.status !== 200) {
        dispatch(actions.addError(meta.name, errMsg))
        const consoleErrMsg = event.target.response
          ? `${errMsg} (${event.target.response})`
          : errMsg
        console.error(consoleErrMsg)
      }
    }
    xhr.send(data)
  } else if (demo) {
    let progress = 0
    const tick = setInterval(() => {
      progress += Math.floor(Math.random() * 5)
      if (progress < 100) {
        dispatch(actions.setProgress(meta.name, progress))
      } else {
        dispatch(actions.clearProgress(meta.name))
        clearInterval(tick)
      }
    }, 100)
  }
}

/**
 * Initializes all FileInput instances called for by the page.
 * @param store - The Redux store.
 */

const initFileInputDrag = store => {
  const inputs = initSelect('input[type="file"].file-drag')
  inputs.forEach(input => {
    const wrapper = utils.replaceWrap(input)
    const name = input.getAttribute('name')

    render(
      <Container store={store}>
        <FileInputDrag
          name={name}
          multiple={input.getAttribute('multiple') !== null}
          onUpload={onUpload} />
      </Container>,
      wrapper
    )

    store.dispatch(actions.addMisc(name, 'demo', utils.hasClass(input, 'demo')))
    store.dispatch(actions.addMisc(name, 'param', input.dataset.name))
    store.dispatch(actions.addMisc(name, 'upload', input.dataset.upload))
  })
}

export {
  FileInputDrag,
  initFileInputDrag
}
