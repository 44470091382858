/**
 * Specialized element selection function for initializing components. This
 * method handles the logic common to initialization functions, like limiting
 * selections to children of `.cmmi-patterns` or elements that also have the
 * `.cmmi-patterns` class themselves and checking that elements do not match
 * any of the special conditions under which we should not initialize a
 * component.
 * @param selector - The selector to use to find elements that should be
 *   initialized.
 * @param exact - If set to true, the selector is used exactly as provided.
 *   The default is `false`, in which case the selector is modified so that
 *   it only matches the children of a `.cmmi-patterns` element, or elements
 *   that also have the `.cmmi-patterns` class themselves.
 * @returns {Array} - An array of matching elements.
 */

export default function initSelect (selector, exact = false) {
  const all = (exact)
    ? document.querySelectorAll(selector)
    : document.querySelectorAll(`.cmmi-patterns ${selector}, ${selector}.cmmi-patterns`)
  const selected = []
  Array.from(all).forEach(el => {
    const check = el.closest('.do-not-initialize, body.DesignMode')
    if (!check) {
      selected.push(el)
    }
  })
  return selected
}
