import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  MegamenuComponent as component,
  MegamenuMap as map,
  MegamenuActions as actions
} from '@cmmi/cmmi-react'
import { Container } from './container'

const Megamenu = connect(map)(component)

/**
 * This method initializes a megamenu in the site header.
 * @param store - The Redux store.
 */

const initMegamenu = store => {
  const el = document.querySelector('.cmmi-patterns header.site, header.site.cmmi-patterns')
  if (el) {
    const curr = el.querySelector('li.current > a')
      ? el.querySelector('li.current > a')
      : 'none'
    const classes = el.getAttribute('class').split(' ')
    const domain = el.dataset.domain || 'https://cmmiinstitute.com'
    const src = el.dataset.src || 'https://cmmiinstitute.com/json/megamenu'
    const matches = el.querySelector('.secondary .cart').textContent.match(/\((\d*)\)/)
    const inCart = matches.length > 1 ? parseInt(matches[1]) : 0
    const contentEl = el.querySelector('.content')
    const content = contentEl
      ? {
        classes: contentEl.getAttribute('class').split(' '),
        markup: contentEl.innerHTML
      }
      : { classes: ['content'], markup: '' }

    if (el.querySelector('.secondary.logged-in')) { store.dispatch(actions.isLoggedIn()) }
    if (inCart > 0) { store.dispatch(actions.setCart(inCart)) }

    render(
      <Container store={store}>
        <Megamenu
          classes={classes}
          domain={domain}
          current={curr.innerHTML}
          inCart={inCart}
          content={content}
          src={src} />
      </Container>,
      el
    )
  }
}

export {
  Megamenu,
  initMegamenu
}
