import { combineReducers } from 'redux'

import {
  CardSelectionReducer,
  ChallengeSolutionReducer,
  CharLimitReducer,
  CMMIV2InfographicReducer,
  CybermaturityRoadmapExplainerReducer,
  DropdownReducer,
  FavoritesReducer,
  FigureReducer,
  FileInputReducer,
  FileInputDragReducer,
  GaugeListReducer,
  ImageGalleryReducer,
  MegamenuReducer,
  MultiselectReducer,
  ResponderReducer,
  RevealableReducer,
  SolutionChoiceReducer,
  TabbedReducer,
  TagSelectReducer,
  TogglableReducer,
  VideoReducer
} from '@cmmi/cmmi-react'

export default combineReducers({
  CardSelectionReducer,
  ChallengeSolutionReducer,
  CharLimitReducer,
  CMMIV2InfographicReducer,
  CybermaturityRoadmapExplainerReducer,
  DropdownReducer,
  FavoritesReducer,
  FigureReducer,
  FileInputReducer,
  FileInputDragReducer,
  GaugeListReducer,
  ImageGalleryReducer,
  MegamenuReducer,
  MultiselectReducer,
  ResponderReducer,
  RevealableReducer,
  SolutionChoiceReducer,
  TabbedReducer,
  TagSelectReducer,
  TogglableReducer,
  VideoReducer
})
