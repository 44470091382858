import { createStore, applyMiddleware, compose } from 'redux'
import { utils } from '@cmmi/cmmi-react'
import thunk from 'redux-thunk'
import 'babel-polyfill'

import { initCardSelection } from './enhancements/card-selection'
import { initChallengeSolution } from './enhancements/challenge-solution'
import { initCharLimit } from './enhancements/char-limit'
import { initCMMIV2Infographic } from './enhancements/cmmi-v2-infographic'
import { initCybermaturityRoadmapExplainer } from './enhancements/cybermaturity-roadmap-explainer'
import { initDropdown } from './enhancements/dropdown'
import { initEditableTable } from './enhancements/editable-table'
import { initFavorites } from './enhancements/favorites'
import { initFigures } from './enhancements/figure'
import { initFileInput } from './enhancements/file-input'
import { initFileInputDrag } from './enhancements/file-input-drag'
import { initGaugeList } from './enhancements/gauge-list'
import { initImageGallery } from './enhancements/image-gallery'
import { initMegamenu } from './enhancements/megamenu'
import { initMultiselect } from './enhancements/multiselect'
import { initResponder } from './enhancements/responder'
import { initRevealable } from './enhancements/reveealable'
import { initSolutionChoice } from './enhancements/solution-choice'
import { initTabbed } from './enhancements/tabbed'
import { initTagSelect } from './enhancements/tag-select'
import { initTogglable } from './enhancements/togglable'
import { initVideo } from './enhancements/video'

import { initSmoothScroll } from './enhancements/smooth-scroll'
import { initTabable } from './enhancements/tabable'
import { initTop } from './enhancements/top'

import reducers from './reducers'

const patterned = document.querySelector('.cmmi-patterns')
const inKentico = patterned && (patterned.closest('.CMSDeskContent') !== null)

if (patterned && !inKentico) {
  // Mark if this is a touch device.
  if ('ontouchstart' in document.documentElement) {
    utils.addClass(patterned, 'touchable')
  }

  // Maybe add Redux dev tools.
  const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  const store = (devTools !== undefined)
    ? createStore(reducers, compose(applyMiddleware(thunk), devTools))
    : createStore(reducers, applyMiddleware(thunk))

  // Initialize smart components
  const smart = [
    initCardSelection,
    initChallengeSolution,
    initCharLimit,
    initCMMIV2Infographic,
    initCybermaturityRoadmapExplainer,
    initDropdown,
    initFavorites,
    initFigures,
    initFileInput,
    initFileInputDrag,
    initGaugeList,
    initImageGallery,
    initMegamenu,
    initMultiselect,
    initResponder,
    initRevealable,
    initSolutionChoice,
    initTabbed,
    initTagSelect,
    initTogglable,
    initVideo
  ]

  smart.forEach(fn => {
    fn(store)
  })

  // Initialize dumb components
  initEditableTable()
  initSmoothScroll()
  initTabable()
  initTop()
}
