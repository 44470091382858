import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  ResponderComponent as component,
  ResponderMap as map
} from '@cmmi/cmmi-react'

const Responder = connect(map)(component)

/**
 * Adds an instance of the Responder container to the page.
 * @param store - The Redux store.
 */

const initResponder = store => {
  const body = document.querySelector('body')
  const wrapper = document.createElement('div')
  if (body) {
    body.appendChild(wrapper)

    render(
      <Container store={store}>
        <Responder />
      </Container>,
      wrapper
    )
  }
}

export {
  Responder,
  initResponder
}
