import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  MultiselectComponent as component,
  MultiselectMap as map,
  MultiselectActions as actions,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const Multiselect = connect(map)(component)

/**
 * Initializes all Multiselect instances called for by the page.
 * @param store - The Redux store.
 */

const initMultiselect = store => {
  const selects = initSelect('select[multiple]')

  selects.forEach(select => {
    const name = select.name
    const options = Array.from(select.options).map(option => ({
      value: option.value,
      label: option.label,
      selected: option.selected
    }))

    store.dispatch(actions.register(name, options))

    const wrapper = utils.replaceWrap(select)

    render(
      <Container store={store}>
        <Multiselect name={name} />
      </Container>,
      wrapper
    )
  })
}

export {
  Multiselect,
  initMultiselect
}
