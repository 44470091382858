import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  TogglableComponent as component,
  TogglableMap as map,
  TogglableActions as actions,
  utils
} from '@cmmi/cmmi-react'
import initSelect from '../init-select'

const Togglable = connect(map)(component)

/**
 * Initializes all Togglable instances called for by the page.
 * @param store - The Redux store.
 */

const initTogglable = store => {
  const agent = window.navigator.userAgent
  const isIE = agent.indexOf('MSIE') > -1 || agent.indexOf('Trident/') > -1
  if (!isIE) {
    const toggles = initSelect('.togglable-toggle')
    toggles.forEach((toggle, index) => {
      const content = utils.hasClass(toggle.nextElementSibling, 'togglable-content') ? toggle.nextElementSibling : null
      if (content) {
        const wrapper = document.createElement('div')
        utils.addClass(wrapper, 'togglable')
        content.parentNode.insertBefore(wrapper, content.nextSibling)

        if (utils.hasClass(content, 'default-open')) {
          store.dispatch(actions.open(index.toString()))
        }

        render(
          <Container store={store}>
            <Togglable
              toggle={toggle.innerHTML}
              tag={toggle.tagName}
              content={content.innerHTML}
              height={content.offsetHeight}
              index={index.toString()} />
          </Container>,
          wrapper
        )

        toggle.parentNode.removeChild(toggle)
        content.parentNode.removeChild(content)
      }
    })
  }
}

export {
  Togglable,
  initTogglable
}
