import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  CMMIV2InfographicComponent as component,
  CMMIV2InfographicMap as map
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const CMMIV2Infographic = connect(map)(component)

/**
 * Initializes all CMMIV2Infographic instances called for by the page.
 * @param store - The Redux store.
 */

const initCMMIV2Infographic = store => {
  const items = initSelect('.cmmi-v2-infographic')
  items.forEach((item, index) => {
    const callouts = {}
    const elems = item.querySelectorAll('.cmmi-v2-infographic-callout')
    Array.from(elems).forEach(el => {
      let key = null
      const classes = el.className.split(' ')
      classes.forEach(className => {
        if (className !== 'cmmi-v2-infographic-callout') key = className
      })
      if (key) {
        callouts[key] = {
          url: el.querySelector('h3 a') ? el.querySelector('h3 a').getAttribute('href') : null,
          heading: el.querySelector('h3') ? el.querySelector('h3').textContent : null,
          desc: el.querySelector('p') ? el.querySelector('p').textContent : null
        }
      }
    })

    render(
      <Container store={store}>
        <CMMIV2Infographic
          index={index}
          callouts={callouts} />
      </Container>,
      item
    )
  })
}

export {
  CMMIV2Infographic,
  initCMMIV2Infographic
}
