/* global FormData, XMLHttpRequest */

import { utils } from '@cmmi/cmmi-react'
import initSelect from '../init-select'

/**
 * This method is fired when an `input` or `select` element within an eidtable
 * table receives focus.
 * @param event {Object} - The focus event object.
 */

const onFocus = event => {
  const tr = utils.closest(event.target, 'tr')
  utils.addClass(tr, 'editing')
}

/**
 * This method is fired when an `input` or `select` element within an editable
 * table loses focus.
 * @param event {Object} - The blur event object.
 */

const onBlur = event => {
  const tr = utils.closest(event.target, 'tr')
  utils.removeClass(tr, 'editing')
}

/**
 * Turns a form's `action` attribute into an absolute URL.
 * @param form {Element} - The HTML form element.
 * @returns {string|boolean} - Returns `false` if the form does not have an
 *   action attribute; otherwise, it returns the absolute URL that the form's
 *   action attribute points to.
 */

const qualifyAction = form => {
  const action = form && form.getAttribute('action') ? form.getAttribute('action') : false
  if (!action) {
    return false
  } else if (action.startsWith('http')) {
    return action
  } else if (action.startsWith('/')) {
    return `${window.location.protocol}//${window.location.host}${action}`
  } else {
    const url = window.location.href.split('/')
    url.pop()
    return `${url.join('/')}/${action}`
  }
}

/**
 * Processes the form submission on an editable table.
 * @param event {Object} - The submission event.
 */

const onSubmit = event => {
  event.stopPropagation()
  event.preventDefault()

  const target = event && event.explicitOriginalTarget
    ? event.explicitOriginalTarget
    : null
  const targetID = target ? target.getAttribute('value') : null
  const form = event.target
  const url = qualifyAction(form)

  if (url) {
    const check = window.confirm('Are you sure you want to delete this record?')
    if (check) {
      // Optimistic feedback
      const row = utils.closest(target, 'tr')
      utils.addClass(row, 'removing')

      // Submit form
      const data = new FormData(form)
      data.append('remove', targetID)
      const xhr = new XMLHttpRequest()
      xhr.open('POST', url)
      xhr.send(data)
      xhr.addEventListener('load', () => {
        // Confirmed feedback
        const accepted = [200, 204, 301, 307, 308, 404]
        if (accepted.indexOf(xhr.status) > -1) {
          row.parentNode.removeChild(row)
        } else {
          utils.removeClass(row, 'removing')
        }
      })
    }
  }
}

/**
 * Initializes editable tables.
 */

const initEditableTable = () => {
  const tables = initSelect('table.editable')
  tables.forEach(table => {
    const form = utils.closest(table, 'form')
    if (form && form.getAttribute('action')) {
      form.addEventListener('submit', onSubmit)
    }

    const inputs = form.querySelectorAll('input, select')
    inputs.forEach(input => {
      input.addEventListener('focus', onFocus)
      input.addEventListener('blur', onBlur)
    })
  })
}

export { initEditableTable }
