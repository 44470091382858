import React from 'react'
import { render } from 'react-dom'
import initSelect from '../init-select'
import { SmoothScrollLink, utils } from '@cmmi/cmmi-react'

/**
 * Initializes all SmoothScrollLink instances called for by the page.
 */

const initSmoothScroll = () => {
  const links = initSelect('a.smooth-scroll')
  links.forEach(link => {
    const wrapper = utils.replaceWrap(link)

    render(
      <SmoothScrollLink
        href={link.getAttribute('href')}
        classes={link.className}
        content={link.innerHTML} />,
      wrapper
    )
  })
}

export {
  SmoothScrollLink,
  initSmoothScroll
}
