import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  ImageGalleryComponent as component,
  ImageGalleryMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const ImageGallery = connect(map)(component)

/**
 * Initializes all ImageGallery instances called for by the page.
 * @param store - The Redux store.
 */

const initImageGallery = store => {
  const galleries = initSelect('.image-gallery')

  galleries.forEach((gallery, index) => {
    const items = []
    const images = Array.from(gallery.querySelectorAll('a'))
    images.forEach(image => {
      const img = image.querySelector('img')
      if (img) {
        items.push({
          href: image.getAttribute('href'),
          src: img.getAttribute('src'),
          alt: img.getAttribute('alt')
        })
      }
    })

    const wrapper = utils.replaceWrap(gallery)
    utils.addClass(wrapper, 'image-gallery')

    render(
      <Container store={store}>
        <ImageGallery
          index={index}
          items={items} />
      </Container>,
      wrapper
    )
  })
}

export {
  ImageGallery,
  initImageGallery
}
