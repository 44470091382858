import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  RevealableComponent as component,
  RevealableMap as map
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const Revealable = connect(map)(component)

/**
 * Initializes all Revealable instances called for by the page.
 * @param store - The Redux store.
 */

const initRevealable = store => {
  const containers = initSelect('.revealable')
  containers.forEach((container, index) => {
    render(
      <Container store={store}>
        <Revealable
          instructions={container.dataset['instructions']}
          contents={container.innerHTML}
          index={index.toString()} />
      </Container>,
      container
    )
  })
}

export {
  Revealable,
  initRevealable
}
