import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  GaugeListComponent as component,
  GaugeListMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const GaugeList = connect(map)(component)

/**
 * Initializes all GaugeList instances called for by the page.
 * @param store - The Redux store.
 */

const initGaugeList = store => {
  const lists = initSelect('.gauge-list:not(.initialized)')
  lists.forEach((list, index) => {
    const items = []
    const levelNames = list.dataset.levelNames.split(',').map(name => name.trim())
    const li = list.querySelectorAll('.cards > li')
    Array.from(li).forEach((item, index) => {
      if (item.tagName.toLowerCase() === 'li') {
        items.push({
          classes: item.className,
          level: parseInt(item.dataset.level) - 1,
          html: item.innerHTML,
          index
        })
      }
    })

    const wrapper = utils.replaceWrap(list)
    utils.addClass(wrapper, 'gauge-list', 'initialized')

    render(
      <Container store={store}>
        <GaugeList
          index={index}
          names={levelNames}
          items={items} />
      </Container>,
      wrapper
    )
  })
}

export {
  GaugeList,
  initGaugeList
}
