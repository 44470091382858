import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ErrorBoundary } from './error-boundary'

/**
 * We distinguish "containers" from "components" along the lines of "smart
 * components" (containers) and "dumb components" (components). If a compon-
 * ent needs to access state, it is a container. The Container component
 * packages the Provider and ErrorBoundary higher order components (HOC's)
 * into a single HOC for convenience, since every one of our containers should
 * use both of them.
 */

class Container extends React.Component {
  render () {
    return (
      <Provider store={this.props.store}>
        <ErrorBoundary>
          {this.props.children}
        </ErrorBoundary>
      </Provider>
    )
  }
}

Container.propTypes = {
  children: PropTypes.element.isRequired,
  store: PropTypes.object
}

export { Container }
