import React from 'react'
import { render } from 'react-dom'
import { Top, utils } from '@cmmi/cmmi-react'

/**
 * Adds a Top component if the page requests one.
 */

const initTop = () => {
  const body = document.querySelector('body.return-to-top')
  if (body) {
    const wrapper = document.createElement('div')
    utils.addClass(wrapper, 'return-to-top')
    body.appendChild(wrapper)
    render(
      <Top />,
      wrapper
    )
  }
}

export {
  Top,
  initTop
}
