import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  CharLimitComponent as component,
  CharLimitMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const CharLimit = connect(map)(component)

/**
 * Initializes all CharLimit instances called for by the page.
 * @param store - The Redux store.
 */

const initCharLimit = store => {
  const limits = initSelect('textarea + .char-limit')
  limits.forEach((limit, index) => {
    const textarea = limit.previousElementSibling
    if ((textarea.tagName.toLowerCase() === 'textarea') && limit.dataset.limit) {
      const wrapper = utils.replaceWrap(limit)
      render(
        <Container store={store}>
          <CharLimit
            name={textarea.getAttribute('name') ? textarea.getAttribute('name') : `char-limit-${index}`}
            textarea={textarea}
            limit={limit.dataset.limit ? parseInt(limit.dataset.limit) : 100} />
        </Container>,
        wrapper
      )
    }
  })
}

export {
  CharLimit,
  initCharLimit
}
