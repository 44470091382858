import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  CybermaturityRoadmapExplainerComponent as component,
  CybermaturityRoadmapExplainerMap as map
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const CybermaturityRoadmapExplainer = connect(map)(component)

/**
 * Initializes all CybermaturityRoadmapExplainer instances called for by the
 * page.
 * @param store - The Redux store.
 */

const initCybermaturityRoadmapExplainer = store => {
  const items = initSelect('.cybermaturity-roadmap-explainer')
  items.forEach((item, index) => {
    const figures = []
    const elems = item.querySelectorAll('figure')
    Array.from(elems).forEach(el => {
      const img = el.querySelector('img')
      const cap = el.querySelector('figcaption')
      figures.push({
        img: img ? img.getAttribute('src') : 'https://cmmiinstitute.com/CMMI/media/Cybermaturity/roadmap-screen1.jpg',
        cap: cap ? cap.innerHTML : 'The roadmap is prioritized based on your organization&rsquo;s biggest threats and risks.'
      })
    })

    render(
      <Container store={store}>
        <CybermaturityRoadmapExplainer
          index={index}
          captions={figures} />
      </Container>,
      item
    )
  })
}

export {
  CybermaturityRoadmapExplainer,
  initCybermaturityRoadmapExplainer
}
