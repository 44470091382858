import initSelect from '../init-select'

/**
 * Initializes all Tabable instances called for by the page.
 */

const initTabable = () => {
  const fields = initSelect('.tabable')
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].tagName.toLowerCase() === 'input') {
      const next = i < fields.length - 1
        ? fields[i + 1]
        : null
      fields[i].addEventListener('input', event => {
        if (next && (event.target.value.length >= event.target.maxLength)) {
          if (next.tagName.toLowerCase() === 'input') {
            next.focus()
          } else {
            const select = next.querySelector('select')
            if (select) select.focus()
          }
        }
      })
    }
  }
}

export {
  initTabable
}
