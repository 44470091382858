import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  ChallengeSolutionComponent as component,
  ChallengeSolutionMap as map,
  utils
} from '@cmmi/cmmi-react'
import initSelect from '../init-select'

const ChallengeSolution = connect(map)(component)

/**
 * Initializes all ChallengeSolution instances called for by the page.
 * @param store - The Redux store.
 */

const initChallengeSolution = store => {
  const challenges = []
  const lists = initSelect('.challenge-solution:not(.initialized)')
  lists.forEach((list, index) => {
    Array.from(list.children).forEach(child => {
      if (child.tagName.toLowerCase() === 'li') {
        const statement = child.querySelector('.challenge-statement')
        const lead = child.querySelector('.then')
        const solution = child.querySelector('.solution')
        const cta = child.querySelector('.cta')
        challenges.push({
          className: child.className,
          statement: statement ? statement.innerHTML : null,
          lead: lead ? lead.innerHTML : null,
          solution: solution ? solution.innerHTML : null,
          cta: {
            url: cta ? cta.getAttribute('href') : null,
            text: cta ? cta.innerHTML : null
          }
        })
      }
    })

    const data = {
      challenges,
      index
    }

    const wrapper = utils.replaceWrap(list)
    utils.addClass(wrapper, 'challenge-solution', 'initialized')

    render(
      <Container store={store}>
        <ChallengeSolution
          data={data} />
      </Container>,
      wrapper
    )
  })
}

export {
  ChallengeSolution,
  initChallengeSolution
}
