import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  FileInputComponent as component,
  FileInputMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const FileInput = connect(map)(component)

/**
 * Initializes all FileInput instances called for by the page.
 * @param store - The Redux store.
 */

const initFileInput = store => {
  const inputs = initSelect('input[type="file"]:not(.file-drag)')
  inputs.forEach(input => {
    const wrapper = utils.replaceWrap(input)

    render(
      <Container store={store}>
        <FileInput
          name={input.getAttribute('name')}
          multiple={input.getAttribute('multiple') !== null} />
      </Container>,
      wrapper
    )
  })
}

export {
  FileInput,
  initFileInput
}
