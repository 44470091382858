import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  CardSelectionComponent as component,
  CardSelectionMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const CardSelection = connect(map)(component)

/**
 * Removes the children of a given element that match a given selector.
 * @param el - The parent element.
 * @param selector - The selector to use to find descendants, which will be
 *   removed.
 */

const removeSelector = (el, selector) => {
  const toRemove = el.querySelector(selector)
  if (toRemove) {
    toRemove.parentNode.removeChild(toRemove)
  }
}

/**
 * Returns just the interior markup from a section, without the heading or the
 * card markup.
 * @param el - The original element.
 * @returns {string} - An HTML string of the original element sans the `h2` and
 *   `.selection-card` elements.
 */

const getMarkup = el => {
  const dummy = document.createElement('div')
  dummy.setAttribute('style', 'display: none; visibility: hidden;')
  dummy.innerHTML = el.innerHTML

  removeSelector(dummy, 'h2')
  removeSelector(dummy, '.selection-card')

  return dummy.innerHTML
}

/**
 * Initializes all CardSelection instances called for by the page.
 * @param store - The Redux store.
 */

const initCardSelection = store => {
  const sections = initSelect('.card-selection')

  const sets = []
  let set = []
  let lastSeen = null

  // Group contiguous `.card-selection` sections together into sets.
  sections.forEach(section => {
    if (!lastSeen || (lastSeen.nextElementSibling === section)) {
      set.push(section)
    } else {
      sets.push(set)
      set = [section]
    }
    lastSeen = section
  })
  sets.push(set)

  sets.forEach((set, index) => {
    // Collect data
    const data = []
    let showAll = 'Show All'
    let collapseAll = 'Collapse All'
    set.forEach(section => {
      const heading = section.querySelector('h2')
      const card = section.querySelector('.selection-card')
      const markup = getMarkup(section)

      if (section.dataset['showAll']) { showAll = section.dataset['showAll'] }
      if (section.dataset['collapseAll']) { showAll = section.dataset['collapseAll'] }

      data.push({
        heading: heading.innerHTML,
        card: card.innerHTML,
        markup
      })
    })

    // Set up wrapper, remove HTML we won't be using now that we have our data.
    if (set.length > 0) {
      const wrapper = document.createElement('div')
      utils.addClass(wrapper, 'card-selection', 'initialized')
      set[0].parentNode.insertBefore(wrapper, set[0])
      set.forEach(section => {
        section.parentNode.removeChild(section)
      })

      // Render component
      render(
        <Container store={store}>
          <CardSelection
            cards={data}
            index={index}
            showAll={showAll}
            collapseAll={collapseAll} />
        </Container>,
        wrapper
      )
    }
  })
}

export {
  CardSelection,
  initCardSelection
}
