import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  componentDidCatch (err) {
    console.error(err)
  }

  render () {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
}

export { ErrorBoundary }
