import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  VideoComponent as component,
  VideoMap as map,
  utils
} from '@cmmi/cmmi-react'
import initSelect from '../init-select'

const Video = connect(map)(component)

/**
 * Initializes all Video instances called for by the page.
 * @param store - The Redux store.
 */

const initVideo = store => {
  const links = initSelect('a.youtube, a.video, a.vimeo')
  links.forEach(link => {
    const wrapper = utils.replaceWrap(link)
    const service = utils.hasClass(link, 'vimeo') ? 'vimeo' : 'youtube'
    render(
      <Container store={store}>
        <Video
          href={link.getAttribute('href')}
          content={link.innerHTML}
          service={service} />
      </Container>,
      wrapper
    )
  })
}

export {
  Video,
  initVideo
}
