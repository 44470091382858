import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  TabbedComponent as component,
  TabbedMap as map,
  utils
} from '@cmmi/cmmi-react'
import initSelect from '../init-select'

const Tabbed = connect(map)(component)

/**
 * Initializes all Tabbed instances called for by the page.
 * @param store - The Redux store.
 */

const initTabbed = store => {
  const sets = initSelect('.tabbed')
  sets.forEach((set, index) => {
    const tabs = []
    const content = set.querySelectorAll('.tab')

    Array.from(content).forEach((tab, i) => {
      tabs.push({
        name: tab.dataset.tab ? tab.dataset.tab : `Tab ${i + 1}`,
        markup: tab.innerHTML
      })
    })

    render(
      <Container store={store}>
        <Tabbed
          index={index.toString()}
          data={tabs} />
      </Container>,
      set
    )

    utils.addClass(set, 'initialized')
  })
}

export {
  Tabbed,
  initTabbed
}
