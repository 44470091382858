import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  FigureComponent as component,
  FigureMap as map,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const Figure = connect(map)(component)

/**
 * Initializes all images in figures on the page.
 * @param store - The Redux store.
 */

const initFigures = store => {
  const images = initSelect('figure > img')

  images.forEach(img => {
    const classes = img.getAttribute('class') ? img.getAttribute('class').split(' ') : []
    const src = img.getAttribute('src')
    const alt = img.getAttribute('alt')
    const wrapper = utils.replaceWrap(img)

    render(
      <Container store={store}>
        <Figure
          classes={classes}
          src={src}
          alt={alt} />
      </Container>,
      wrapper
    )
  })
}

export {
  Figure,
  initFigures
}
