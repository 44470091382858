import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  TagSelectComponent as component,
  TagSelectMap as map,
  utils
} from '@cmmi/cmmi-react'
import initSelect from '../init-select'

const TagSelect = connect(map)(component)

/**
 * Initializes all TagSelect instances called for by the page.
 * @param store - The Redux store.
 */

const initTagSelect = store => {
  const selects = initSelect('select.tag-select')
  selects.forEach((select, index) => {
    const options = utils.getOptions(select)
    const wrapper = utils.replaceWrap(select)
    utils.addClass(wrapper, 'tag-select-wrapper')

    render(
      <Container store={store}>
        <TagSelect
          name={select.getAttribute('name')}
          options={options}
          index={index.toString()} />
      </Container>,
      wrapper
    )
  })
}

export {
  TagSelect,
  initTagSelect
}
