import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import { Container } from './container'
import {
  SolutionChoiceComponent as component,
  SolutionChoiceMap as map,
  utils
} from '@cmmi/cmmi-react'
import initSelect from '../init-select'

const SolutionChoice = connect(map)(component)

/**
 * Initializes all SolutionChoice instances called for by the page.
 * @param store - The Redux store.
 */

const initSolutionChoice = store => {
  const items = initSelect('.cmmi-patterns .solution-choice > li, .solution-choice.cmmi-patterns > li', true)
  items.forEach((item, index) => {
    const img = item.querySelector('img')
    const h3 = item.querySelector('h3')
    const expand = item.querySelector('.expand')

    render(
      <Container store={store}>
        <SolutionChoice
          index={index.toString()}
          heading={h3 ? h3.innerHTML : (img && img.getAttribute('alt')) ? img.getAttribute('alt') : ''}
          src={img ? img.getAttribute('src') : ''}
          markup={expand ? expand.innerHTML : ''} />
      </Container>,
      item
    )

    utils.addClass(item, 'initialized')
  })
}

export {
  SolutionChoice,
  initSolutionChoice
}
