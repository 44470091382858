import React from 'react'
import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  FavoritesComponent as component,
  FavoritesMap as map,
  FavoritesActions as actions,
  utils
} from '@cmmi/cmmi-react'
import { Container } from './container'
import initSelect from '../init-select'

const Favorites = connect(map)(component)

/**
 * Initializes all Favorites instances called for by the page.
 * @param store - The Redux store.
 */

const initFavorites = store => {
  const faves = initSelect('a.fav')
  faves.forEach(fav => {
    const isFav = utils.hasClass(fav, 'is-fav')
    const url = fav.getAttribute('href')
    const wrapper = utils.replaceWrap(fav)
    utils.addClass(wrapper, 'fav-initialized')

    if (isFav) {
      store.dispatch(actions.setFave(url))
    }

    render(
      <Container store={store}>
        <Favorites
          url={url}
          isFav={isFav} />
      </Container>,
      wrapper
    )
  })
}

export {
  Favorites,
  initFavorites
}
